import uniqid from 'uniqid'
import { skills } from '../../portfolio'
import './Skills.css'
import { useTranslation } from 'react-i18next'
import IconCloud from "../Magicui/IconCloud.tsx";
import "aos/dist/aos.css"
import AOS from 'aos';


const Skills = () => {
  const slugs = [
    "visualstudiocode",
    "phpstorm",
    "html5",
    "css3",
    "bootstrap",
    "javascript",
    "python",
    "php",
    "react",
    "laravel",
    "symfony",
    "flask",
    "mysql",
    "postgresql",
    "mongodb",
    "firebase",
    "redux",
    "docker",
    "sonarqube",
    "jira",
    "github",
    "gitlab",
    "vercel",
    "netlify",
    "nginx",
    "amazonaws",
    "microsoftazure",
    "wordpress",
    "drupal",
    "git",
  ];

  const { t, i18n: { changeLanguage, language } } = useTranslation();


  if (!skills.length) return null

  return (
    <section className='section skills' id='skills'>
      <h2 className='section__title' data-aos="zoom-out">
        {t('skills', { appName: "App for Translations" })}
      </h2>
      <ul className='skills__list' >
        {skills.map((skill) => (
          <li key={uniqid()} className='skills__list-item btn btn--plain' data-aos="zoom-out">
            {/* <i class={skill.logo} aria-hidden="true"></i> */}
            <span>{skill.name}</span>
          </li>
        ))}
      </ul>
      <br></br>
      <div id="magicui_section" data-aos="zoom-out" className="relative flex h-full w-full max-w-[32rem] items-center justify-center overflow-hidden rounded-lg border bg-background px-20 pb-20 pt-8 ">
        <IconCloud iconSlugs={slugs} />
      </div>
    </section>
  )
}

export default Skills
